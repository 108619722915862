<template>
  <div class="backoffice_general-orders">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('backoffice.orders.general_orders.alert')"></span>
    </el-alert>
    <el-table
      id="ordersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="defaultSort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-contacts-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="userName"
        :label="$t('general.label.user')"
        min-width="180"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="{ row }">
          <a href="#" @click.prevent="showUserDetails(row)">{{
            row.userName
          }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderStatus"
        :label="$t('general.label.status')"
        :show-overflow-tooltip="true"
        min-width="110"
      ></el-table-column>
      <el-table-column
        prop="orderStatus"
        :label="$t('general.label.handle')"
        :show-overflow-tooltip="true"
        min-width="110"
      ></el-table-column>
      <el-table-column
        prop="service"
        :label="$t('general.label.service')"
        :show-overflow-tooltip="true"
        min-width="110"
      ></el-table-column>
      <el-table-column
        prop="action"
        :label="$t('general.label.action')"
        min-width="170"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        class-name="actions-cell justify-between"
        prop="mobibCardNumber"
        :label="$t('general.label.card_number')"
        :show-overflow-tooltip="true"
        width="220"
      >
        <template slot-scope="{ row }">
          <template v-if="row.mobibCardNumber || row.cardNumber">
            <span style="margin-right: 10px">{{ selectCardNumber(row) }}</span>
            <base-button
              @click.stop="handleCopy(selectCardNumber(row))"
              size="small"
              prepend-icon="ri-file-copy-line"
            ></base-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="requestedOnDate"
        :label="$t('general.label.requested_on')"
        :show-overflow-tooltip="true"
        min-width="110"
      >
      </el-table-column>
      <el-table-column
        class-name="actions-cell"
        prop="buttons"
        :label="''"
        width="55"
      >
        <template slot-scope="{ row }">
          <base-button
            v-if="row?.cancellationAllowedAfterConfirmation"
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          ></base-button>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="corporateAccountExtId"
        :label="$t('general.label.ca')"
        :show-overflow-tooltip="true"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="partnerExtId"
        :label="$t('general.label.partner')"
        :show-overflow-tooltip="true"
        min-width="150"
      >
      </el-table-column>
    </el-table>
    <!-- <general-order-cancel
      ref="orderCancel"
      @confirm="confirmCancel"
    ></general-order-cancel> -->
    <cancel-order
      ref="cancelSeasonTicketOrder"
      :extraAlert="$t('order_handling.cancel_handled_order.warning_alert_message')"
      @confirm="confirmCancel"
    ></cancel-order>
  </div>
</template>

<script>
import OrderRepository from '@/app/repositories/OrderRepository'
import TableLoader from '@/mixins/TableLoader'
import { debounce } from 'lodash'
import CancelOrder from '@/components/OrderHandling/SeasonCardOrders/CancelOrder'

import CancelRequest from '@/mixins/CancelRequest'

const defaultSort = { order: 'ascending', prop: 'userName' }

const orderRepository = new OrderRepository()
export default {
  name: 'HandledOrders',
  components: { CancelOrder },
  mixins: [CancelRequest, TableLoader],
  props: {
    totalPages: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 0,
          size: 25,
          sort: {}
        }
      }
    }
  },
  data () {
    return {
      defaultSort,
      orders: [],
      tableData: []
    }
  },
  computed: {},
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200)
  },
  methods: {
    fetchData () {
      const loading = this.TableLoader_show()
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: {
          sortOrders: [
            {
              direction: (
                this.pagination.sort.order || defaultSort.order
              ).toUpperCase(),
              property: this.pagination.sort.prop
            }
          ]
        }
      }
      // console.log(pagination)
      return orderRepository.getHandled(
        { pagination },
        {
          cancelToken: this.CancelRequest_token(),
          onSuccess: response => {
            this.setData(response)
            // this.orders = orders
            // const totalPages = Math.ceil(orders.length / this.pagination.size)
            // this.$emit('update:totalPages', totalPages)
          },
          onFinally: () => {
            loading.close()
          }
        }
      )
    },
    handleConfirm (order) {
      this.$refs.orderConfirmation.show(order)
    },
    handleDelete (order) {
      // if (order.action === 'PURCHASE_SEASON_TICKET') {
      //   return this.$refs.cancelSeasonTicketOrder.show(order)
      // }
      this.$refs.cancelSeasonTicketOrder.show(order)
    },
    confirmCancel ({ form }) {
      const order = this.tableData.find(o => o.orderUuid === form.orderUuid)
      console.log('this.tableData', this.tableData)

      console.log('form', form)

      console.log('order', order)

      if (order.action === 'PURCHASE_SEASON_TICKET') {
        return this.handleSeasonTicketOrderCancellation(form)
      }
      return this.handleOrderCancellation(form)
    },
    handleOrderCancellation (form) {
      const loading = this.TableLoader_show()
      return orderRepository.cancel(form, {
        successNotification: this.$t(
          'backoffice.orders.general_orders.cancel.success_notification'
        ),
        onSuccess: () => {
          this.fetchData()
        },
        onFinally: () => {
          loading.close()
        }
      })
    },
    async handleSeasonTicketOrderCancellation (form) {
      const successNotification = this.$t('order_handling.season_card_orders.cancelled_message')
      const loading = this.TableLoader_show()
      const options = {
        successNotification,
        onSuccess: () => {
          this.fetchData()
        },
        onFinally: () => {
          loading.close()
        }
      }
      try {
        await this.$api.seasonTicketOrder.cancelOrder(form, options)
      } catch (e) {
        console.error(e)
      }
    },

    handleCopy (message) {
      this.$copyText(message).then(e => {
        this.$notify({
          title: this.$t('backoffice.orders.general_orders.copy_success.title'),
          message: this.$t(
            'backoffice.orders.general_orders.copy_success.text'
          ),
          type: 'success'
        })
        console.log(e)
      })
    },
    setData ({ content, totalPages }) {
      this.tableData = content
      this.$emit('update:totalPages', totalPages)
    },
    showUserDetails (order) {
      const loading = this.TableLoader_show()
      this.$router.push(
        {
          name: 'AppUserDetailView',
          params: {
            userOspId: order.userOspId
          },
          query: {
            corporateAccountOspId: order.corporateAccountOspId
          }
        },
        () => this.$sleep(50).then(loading.close),
        () => this.$sleep(50).then(loading.close)
      )
    },
    sortChange (sort) {
      // console.log(sort)
      this.pagination.sort = sort
      this.$emit('update:pagination', this.pagination)
    },
    selectCardNumber (row) {
      return row.action === 'PURCHASE_CHARGING_CARD'
        ? row.cardNumber
        : row.mobibCardNumber
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped></style>
